import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { setAuthToken } from '../services/auth';
import API_URL from '../config';

function MixPage() {
  const [mix, setMix] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchMix = async () => {
      try {
        setAuthToken(localStorage.getItem('token'));
        const response = await axios.get(`${API_URL}mixes/${id}/`);
        setMix(response.data);
      } catch (error) {
        console.error('Error fetching mix:', error);
      }
    };

    fetchMix();
  }, [id]);

  if (!mix) return <div>Loading...</div>;

  return (
    <div>
      <h1>{mix.title}</h1>
      <p>Added at: {new Date(mix.added_at).toLocaleString()}</p>
      {mix.src_url && (
        <a href={mix.src_url} target="_blank" rel="noopener noreferrer">
          View Original Mix
        </a>
      )}
    </div>
  );
}

export default MixPage;