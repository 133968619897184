import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_URL from '../config';

function CollageForm({ collage, onSubmit }) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (collage) {
      setTitle(collage.title);
      setDescription(collage.description);
    }
  }, [collage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: { 'Authorization': `Bearer ${token}` }
      };
      const data = { title, description };
      let response;
      if (collage) {
        response = await axios.patch(`${API_URL}collages/${collage.id}/update/`, data, config);
      } else {
        response = await axios.post(`${API_URL}collages/create/`, data, config);
      }
      onSubmit(response.data);
      setTitle('');
      setDescription('');
      alert(collage ? 'Collage updated successfully!' : 'Collage created successfully!');
    } catch (error) {
      console.error('Error submitting collage:', error);
      alert('Error creating/updating collage. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Collage Title"
        required
      />
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Collage Description"
      />
      <button type="submit">{collage ? 'Update' : 'Create'} Collage</button>
    </form>
  );
}

export default CollageForm;