import { useState, useEffect, useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import { search } from '../services/musicServices';

function useSearch(loggedIn, currentService) {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [error, setError] = useState(null);
    const lastRequestTime = useRef(0);
    const minRequestInterval = 1000; // 1 second between requests

    const debouncedSearch = useCallback(
        debounce(async (searchQuery) => {
            if (!loggedIn) return;
            
            const now = Date.now();
            if (now - lastRequestTime.current < minRequestInterval) {
                console.log('Rate limit: Too many requests');
                return;
            }
            
            lastRequestTime.current = now;
            
            try {
                setError(null);
                const searchResults = await search(searchQuery);
                setResults(searchResults);
            } catch (error) {
                console.error('Search error:', error);
                if (error.message === 'QUOTA_EXCEEDED') {
                    setError('Search limit reached. Please try again later.');
                } else {
                    setError('An error occurred while searching. Please try again.');
                }
            }
        }, 1000),
        [loggedIn, search]
    );

    useEffect(() => {
        if (query && loggedIn) {
            debouncedSearch(query);
        } else {
            setResults([]);
        }
    }, [query, loggedIn, debouncedSearch]);

    return { query, setQuery, results, error, debouncedSearch };
}

export default useSearch;