import React from 'react';
import { login } from '../services/musicServices';

function ServiceSelector({ currentService, handleServiceChange }) {
    return (
        <div>
            <button onClick={() => handleServiceChange('spotify')}>
                {currentService === 'spotify' ? 'Use Spotify' : 'Switch to Spotify'}
            </button>
            <button onClick={() => handleServiceChange('apple_music')}>
                {currentService === 'apple_music' ? 'Use Apple Music' : 'Switch to Apple Music'}
            </button>
            <button onClick={login}>Log in to {currentService}</button>
        </div>
    );
}

export default ServiceSelector;