import React from 'react';
import axios from 'axios';
import API_URL from '../config';

function CollageSelectionModal({ collages, onSelect, onClose, selectedMediaForCollage, fetchUserMedia, position }) {
  const handleSelect = async (collageId) => {
    try {
      await axios.post(`${API_URL}collages/${collageId}/add/`, {
        media_type: selectedMediaForCollage.mediaType,
        media_id: selectedMediaForCollage.mediaId
      });
      onClose();
      fetchUserMedia();
    } catch (error) {
      console.error('Error adding to collage:', error);
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: `${position.top}px`,
    left: `${position.left}px`,
    zIndex: 1000,
    backgroundColor: 'white',
    border: '1px solid black',
    padding: '10px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
  };

  return (
    <div style={modalStyle}>
      <h2>Select a Collage</h2>
      <ul>
        {collages.map(collage => (
          <li key={collage.id}>
            <button onClick={() => handleSelect(collage.id)}>
              {collage.title}
            </button>
          </li>
        ))}
      </ul>
      <button onClick={onClose}>Cancel</button>
    </div>
  );
}

export default CollageSelectionModal;