import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { setAuthToken } from '../services/auth';
import { Link } from 'react-router-dom';
import { addToQueue, moveToCollection, removeFromQueue } from '../services/mediaService';
import API_URL from '../config';
import CollageSelectionModal from './CollageSelectionModal';

function Library() {
  const [userMedia, setUserMedia] = useState({
    collection: {
      songs: [],
      albums: [],
      playlists: [],
      mixes: [],
      collages: []
    },
    queue: []
  });
  const [expandedSections, setExpandedSections] = useState({
    queue: false,
    collection: false,
    songs: false,
    albums: false,
    playlists: false,
    mixes: false,
    collages: false
  });
  const [showCollageModal, setShowCollageModal] = useState(false);
  const [selectedMediaForCollage, setSelectedMediaForCollage] = useState(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const fetchUserMedia = async () => {
    try {
      setAuthToken(localStorage.getItem('token'));
      const collectionResponse = await axios.get(`${API_URL}user-media/`);
      const queueResponse = await axios.get(`${API_URL}user-queue/`);
      
      const categorizedMedia = {
        collection: {
          songs: [],
          albums: [],
          playlists: [],
          mixes: [],
          collages: []
        },
        queue: queueResponse.data
      };
      
      collectionResponse.data.forEach(item => {
        if (item.song) categorizedMedia.collection.songs.push(item.song);
        else if (item.album) categorizedMedia.collection.albums.push(item.album);
        else if (item.playlist) categorizedMedia.collection.playlists.push(item.playlist);
        else if (item.mix) categorizedMedia.collection.mixes.push(item.mix);
        else if (item.collage) categorizedMedia.collection.collages.push(item.collage);
      });
      
      setUserMedia(categorizedMedia);
    } catch (error) {
      console.error('Error fetching user media:', error);
    }
  };

  useEffect(() => {
    fetchUserMedia();
  }, []);

  const handleAddToQueue = async (mediaType, mediaId) => {
    try {
      await addToQueue(mediaType, mediaId);
      // Refresh the queue
      fetchUserMedia();
    } catch (error) {
      console.error('Error adding to queue:', error);
    }
  };

  const handleMoveToCollection = async (queueItemId) => {
    try {
      await moveToCollection(queueItemId);
      // Refresh both queue and collection
      fetchUserMedia();
    } catch (error) {
      console.error('Error moving to collection:', error);
    }
  };

  const handleRemoveFromQueue = async (queueItemId) => {
    try {
      await removeFromQueue(queueItemId);
      // Refresh the queue
      fetchUserMedia();
    } catch (error) {
      console.error('Error removing from queue:', error);
    }
  };

  const handleRemoveFromCollection = async (mediaType, mediaId) => {
    try {
      await axios.delete(`${API_URL}user-media/${mediaType}/${mediaId}/`);
      // Refresh the collection
      fetchUserMedia();
    } catch (error) {
      console.error('Error removing from collection:', error);
    }
  };

  const handleAddToCollage = (mediaType, mediaId, event) => {
    const rect = event.target.getBoundingClientRect();
    setModalPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX
    });
    setSelectedMediaForCollage({ mediaType, mediaId });
    setShowCollageModal(true);
  };

  const getSpotifyLink = (type, id) => {
    return `https://open.spotify.com/${type}/${id}`;
  };

  const getYouTubeLink = (id, type) => {
    if (type === 'album') {
      return `https://www.youtube.com/playlist?list=${id}`;
    }
    return `https://www.youtube.com/watch?v=${id}`;
  };

  const getBandcampLink = (url) => {
    return url;
  };

  const getAppleMusicLink = (type, id) => {
    if (type === 'song') {
      return `https://music.apple.com/us/song/${id}`;
    } else if (type === 'album') {
      return `https://music.apple.com/us/album/${id}`;
    }
    return '#'; // Return a fallback link if type is neither song nor album
  };

  const renderMediaList = (mediaList, type) => (
    <div className="centered-list">
      <ul className="left-justify">
        {mediaList.filter(item => type !== 'album' || item.direct_add).map((item, index) => (
          <li key={index}>
            <Link to={`/${type}/${item.id}`}>
              <span>{item.title}</span>
            </Link>
            {type !== 'playlist' && type !== 'mix' && (
              <span> by {item.primary_artists.map(artist => (
                <Link key={artist.id} to={`/artist/${artist.id}`}>{artist.name}</Link>
              )).reduce((prev, curr) => [prev, ', ', curr])}</span>
            )}
            {type === 'playlist' && (
              <span> ({item.song_count} songs)</span>
            )}
            {item.spotify_id && (
              <span> (
                <a href={getSpotifyLink(type === 'song' ? 'track' : type, item.spotify_id)} target="_blank" rel="noopener noreferrer">
                  Spotify
                </a>
              )</span>
            )}
            {item.youtube_id && (
              <span> (
                <a href={getYouTubeLink(item.youtube_id, type)} target="_blank" rel="noopener noreferrer">
                  YouTube
                </a>
              )</span>
            )}
            {item.bandcamp_url && (
              <span> (
                <a href={getBandcampLink(item.bandcamp_url)} target="_blank" rel="noopener noreferrer">
                  Bandcamp
                </a>
              )</span>
            )}
            {item.apple_id && (
              <span> (
                <a href={getAppleMusicLink(type, item.apple_id)} target="_blank" rel="noopener noreferrer">
                  Apple Music
                </a>
              )</span>
            )}
            {(type === 'playlist' || type === 'mix') && item.src_url && (
              <span> (
                <a href={item.src_url} target="_blank" rel="noopener noreferrer">
                  Source
                </a>
              )</span>
            )}
            <div>
              <button onClick={() => handleAddToQueue(type, item.id)} className="add-button">
                + Queue
              </button>
              <button onClick={(event) => handleAddToCollage(type, item.id, event)} className="add-button">
                + Collage
              </button>
              <button onClick={() => handleRemoveFromCollection(type, item.id)} className="remove-button">
                X
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );

  const renderQueueItem = (item) => (
    <li key={item.id} className="left-justify">
      <Link to={`/${item.media_type}/${item.media_id}`}>
        {item.title}
      </Link>
      {item.media_type !== 'playlist' && item.media_type !== 'mix' && item.primary_artists && (
        <span> by {item.primary_artists.map(artist => (
          <Link key={artist.id} to={`/artist/${artist.id}`}>{artist.name}</Link>
        )).reduce((prev, curr) => [prev, ', ', curr])}</span>
      )}
      {item.media_type === 'playlist' && item.song_count && (
        <span> ({item.song_count} songs)</span>
      )}
      {item.media_type === 'mix' && item.src_url && (
        <span> (<a href={item.src_url} target="_blank" rel="noopener noreferrer">Source</a>)</span>
      )}
      <div>
        {!isInCollection(item) && (
          <button onClick={() => handleMoveToCollection(item.id)} className="add-button">
            + Collection
          </button>
        )}
        <button onClick={() => handleRemoveFromQueue(item.id)} className="remove-button">
          X
        </button>
      </div>
    </li>
  );

  const isInCollection = (item) => {
    const { media_type, media_id } = item;
    const collectionTypes = {
      song: 'songs',
      album: 'albums',
      playlist: 'playlists',
      mix: 'mixes'
    };
    const collectionKey = collectionTypes[media_type];
    if (!collectionKey) {
      console.error(`Unknown media type: ${media_type}`);
      return false;
    }
    const collectionArray = userMedia.collection[collectionKey];
    return Array.isArray(collectionArray) && collectionArray.some(
      collectionItem => collectionItem.id === media_id
    );
  };

  return (
    <div className="library-container">
      <h2>
        queue
        <button onClick={() => toggleSection('queue')} className="toggle-button">
          {expandedSections.queue ? '-' : '+'}
        </button>
      </h2>
      {expandedSections.queue && (
        userMedia.queue.length > 0 ? (
          <div className="centered-list">
            <ul className="left-justify">
              {[...userMedia.queue].reverse().map(renderQueueItem)}
            </ul>
          </div>
        ) : (
          <p>Your queue is empty.</p>
        )
      )}

      <h2>
        collection
        <button onClick={() => toggleSection('collection')} className="toggle-button">
          {expandedSections.collection ? '-' : '+'}
        </button>
      </h2>
      
      {expandedSections.collection && (
        <>
          <h3>
            songs
            <button onClick={() => toggleSection('songs')} className="toggle-button">
              {expandedSections.songs ? '-' : '+'}
            </button>
          </h3>
          {expandedSections.songs && (
            userMedia.collection.songs.length > 0 ? renderMediaList(userMedia.collection.songs, 'song') : <p>No songs found.</p>
          )}
          
          <h3>
            albums
            <button onClick={() => toggleSection('albums')} className="toggle-button">
              {expandedSections.albums ? '-' : '+'}
            </button>
          </h3>
          {expandedSections.albums && (
            userMedia.collection.albums.length > 0 ? renderMediaList(userMedia.collection.albums, 'album') : <p>No albums found.</p>
          )}
          
          <h3>
            playlists
            <button onClick={() => toggleSection('playlists')} className="toggle-button">
              {expandedSections.playlists ? '-' : '+'}
            </button>
          </h3>
          {expandedSections.playlists && (
            userMedia.collection.playlists.length > 0 ? renderMediaList(userMedia.collection.playlists, 'playlist') : <p>No playlists found.</p>
          )}
          
          <h3>
            mixes
            <button onClick={() => toggleSection('mixes')} className="toggle-button">
              {expandedSections.mixes ? '-' : '+'}
            </button>
          </h3>
          {expandedSections.mixes && (
            userMedia.collection.mixes.length > 0 ? renderMediaList(userMedia.collection.mixes, 'mix') : <p>No mixes found.</p>
          )}
        </>
      )}
      
      <h2>
        collages
        <button onClick={() => toggleSection('collages')} className="toggle-button">
          {expandedSections.collages ? '-' : '+'}
        </button>
      </h2>
      {expandedSections.collages && (
        <div className="centered-list">
          <ul className="left-justify">
            {userMedia.collection.collages.map(collage => (
              <li key={collage.id}>
                <Link to={`/collage/${collage.id}`}>{collage.title}</Link>
                <button onClick={() => handleRemoveFromCollection('collage', collage.id)} className="remove-button">
                  X
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {showCollageModal && (
        <CollageSelectionModal
          collages={userMedia.collection.collages}
          onSelect={(collageId) => handleAddToCollage(selectedMediaForCollage.mediaType, selectedMediaForCollage.mediaId, collageId)}
          onClose={() => setShowCollageModal(false)}
          selectedMediaForCollage={selectedMediaForCollage}
          fetchUserMedia={fetchUserMedia}
          position={modalPosition}
        />
      )}
    </div>
  );
}

export default Library;