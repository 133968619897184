import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { setAuthToken } from '../services/auth';
import API_URL from '../config';
import withRoleCheck from './withRoleCheck';

function DatabaseEditor() {
  const [data, setData] = useState([]);
  const [category, setCategory] = useState('songs');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editingItem, setEditingItem] = useState(null);
  const [editedValues, setEditedValues] = useState({});

  useEffect(() => {
    fetchData();
  }, [category, page]);

  const fetchData = async () => {
    try {
      setAuthToken(localStorage.getItem('token'));
      const response = await axios.get(`${API_URL}database-data/${category}/?page=${page}`);
      setData(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10)); // Assuming 10 items per page

      // Fetch CSRF token
      const csrfResponse = await axios.get(`${API_URL}csrf-token/`);
      axios.defaults.headers.common['X-CSRFToken'] = csrfResponse.data.csrfToken;
    } catch (error) {
      console.error('Error fetching database data:', error);
    }
  };

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleEdit = (item) => {
    setEditingItem(item);
    setEditedValues({ ...item });
  };

  const handleInputChange = (e) => {
    setEditedValues({ ...editedValues, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      await axios.put(`${API_URL}update-${category}/${editingItem.id}/`, editedValues);
      setEditingItem(null);
      fetchData();
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleDelete = async (itemId) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        await axios.delete(`${API_URL}delete-${category}/${itemId}/`);
        fetchData();
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    }
  };

  const renderEditForm = () => {
    if (!editingItem) return null;

    return (
      <div>
        <h3>Edit {category}</h3>
        {Object.keys(editedValues).map(key => (
          <div key={key}>
            <label>{key}: </label>
            <input
              type="text"
              name={key}
              value={editedValues[key] || ''}
              onChange={handleInputChange}
            />
          </div>
        ))}
        <button onClick={handleSave}>Save</button>
        <button onClick={() => setEditingItem(null)}>Cancel</button>
      </div>
    );
  };

  const renderTable = () => (
    <table>
      <thead>
        <tr>
          {data.length > 0 && Object.keys(data[0]).map(key => (
            <th key={key}>{key}</th>
          ))}
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map(item => (
          <tr key={item.id}>
            {Object.values(item).map((value, index) => (
              <td key={index}>{value}</td>
            ))}
            <td>
              <button onClick={() => handleEdit(item)}>Edit</button>
              <button onClick={() => handleDelete(item.id)}>Delete</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div>
      <h1>Database Editor</h1>
      <div>
        <button onClick={() => handleCategoryChange('songs')}>Songs</button>
        <button onClick={() => handleCategoryChange('albums')}>Albums</button>
        <button onClick={() => handleCategoryChange('artists')}>Artists</button>
        <button onClick={() => handleCategoryChange('playlists')}>Playlists</button>
        <button onClick={() => handleCategoryChange('mixes')}>Mixes</button>
      </div>
      {renderEditForm()}
      <h2>{category.charAt(0).toUpperCase() + category.slice(1)}</h2>
      {renderTable()}
      <div>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNum => (
          <button key={pageNum} onClick={() => handlePageChange(pageNum)} disabled={page === pageNum}>
            {pageNum}
          </button>
        ))}
      </div>
    </div>
  );
}

export default withRoleCheck(DatabaseEditor, 'admin');