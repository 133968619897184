import React, { useState } from 'react';
import axios from 'axios';
import API_URL from '../config';

function UrlSubmitForm({ setError, setSuccessMessage }) {
    const [url, setUrl] = useState('');

    const handleSubmit = async (queueOnly) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${API_URL}process-url/`, 
                { url: url, queue_only: queueOnly },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            console.log('URL processed:', response.data);
            setUrl('');
            setError(null);
            setSuccessMessage(response.data.message || 'URL successfully processed!');
        } catch (error) {
            console.error('Error processing URL:', error);
            if (error.response?.data?.error === 'Playlist already exists') {
                setError('Playlist already exists');
                setSuccessMessage('');
            } else {
                setError(error.response?.data?.error || 'An error occurred while processing the URL.');
                setSuccessMessage('');
            }
        }
    };

    return (
        <div>
            <h4>Submit a URL</h4>
            <input
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter a URL..."
            />
            <button onClick={() => handleSubmit(false)}>+ Collection</button>
            <button onClick={() => handleSubmit(true)}>+ Queue</button>
        </div>
    );
}

export default UrlSubmitForm;