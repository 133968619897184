import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_URL from '../config';
import { setAuthToken } from '../services/auth';

function Friends() {
  const [friends, setFriends] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);
  const [newFriendUsername, setNewFriendUsername] = useState('');

  useEffect(() => {
    fetchFriends();
    fetchFriendRequests();
  }, []);

  const fetchFriends = async () => {
    try {
      setAuthToken(localStorage.getItem('token'));
      const response = await axios.get(`${API_URL}get-friends/`);
      setFriends(response.data);
    } catch (error) {
      console.error('Error fetching friends:', error);
    }
  };

  const fetchFriendRequests = async () => {
    try {
      setAuthToken(localStorage.getItem('token'));
      const response = await axios.get(`${API_URL}get-friend-requests/`);
      setFriendRequests(response.data);
    } catch (error) {
      console.error('Error fetching friend requests:', error);
    }
  };

  const sendFriendRequest = async () => {
    try {
      setAuthToken(localStorage.getItem('token'));
      await axios.post(`${API_URL}send-friend-request/`, { username: newFriendUsername });
      alert('Friend request sent!');
      setNewFriendUsername('');
    } catch (error) {
      console.error('Error sending friend request:', error);
      alert('Failed to send friend request');
    }
  };

  const acceptFriendRequest = async (username) => {
    try {
      setAuthToken(localStorage.getItem('token'));
      await axios.post(`${API_URL}accept-friend-request/`, { username });
      alert('Friend request accepted!');
      fetchFriends();
      fetchFriendRequests();
    } catch (error) {
      console.error('Error accepting friend request:', error);
      alert('Failed to accept friend request');
    }
  };

  return (
    <div>
      <h2>friends</h2>
      <ul>
        {friends.map((friend) => (
          <li key={friend.id}>{friend.username}</li>
        ))}
      </ul>

      <h3>friend requests</h3>
      <ul>
        {friendRequests.map((request) => (
          <li key={request.id}>
            {request.username}
            <button onClick={() => acceptFriendRequest(request.username)}>Accept</button>
          </li>
        ))}
      </ul>

      <div>
        <input
          type="text"
          value={newFriendUsername}
          onChange={(e) => setNewFriendUsername(e.target.value)}
          placeholder="Enter username"
        />
        <button onClick={sendFriendRequest}>Send Friend Request</button>
      </div>
    </div>
  );
}

export default Friends;
