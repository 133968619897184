import { initializeSpotify, handleSpotifyLogin, searchSpotify, getSpotifyLoginStatus } from './spotifyService';
import { initializeAppleMusic, handleAppleMusicLogin, searchAppleMusic, getAppleMusicLoginStatus } from './appleMusicService';

const services = {
  spotify: {
    initialize: initializeSpotify,
    login: handleSpotifyLogin,
    search: searchSpotify,
    getLoginStatus: getSpotifyLoginStatus
  },
  'apple_music': {
    initialize: initializeAppleMusic,
    login: handleAppleMusicLogin,
    search: searchAppleMusic,
    getLoginStatus: getAppleMusicLoginStatus
  }
};

let currentService = 'spotify'; // Default to Spotify

export const setCurrentService = (serviceName) => {
  if (services[serviceName]) {
    currentService = serviceName;
  } else {
    console.error(`Service ${serviceName} not found`);
  }
};

export const getCurrentService = () => currentService;

export const initialize = () => services[currentService].initialize();
export const login = () => services[currentService].login();
export const search = (query) => services[currentService].search(query);
export const getLoginStatus = () => services[currentService].getLoginStatus();