import SpotifyWebApi from 'spotify-web-api-js';
import queryString from 'query-string';

const spotifyApi = new SpotifyWebApi();

const CLIENT_ID = '87b179918d74470e8c2249a312f22702';
const REDIRECT_URI = 'https://lib.rashomon.blue/add';

let isLoggedIn = false;

export const initializeSpotify = () => {
  const parsed = queryString.parse(window.location.hash);
  const token = parsed.access_token;
  if (token) {
    spotifyApi.setAccessToken(token);
    window.history.pushState("", document.title, window.location.pathname);
    isLoggedIn = true;
    return true;
  }
  return false;
};

export const handleSpotifyLogin = () => {
  const scopes = ['user-read-private', 'user-read-email'];
  const url = 'https://accounts.spotify.com/authorize?' +
    queryString.stringify({
      response_type: 'token',
      client_id: CLIENT_ID,
      scope: scopes.join(' '),
      redirect_uri: REDIRECT_URI,
    });
  window.location = url;
};

export const searchSpotify = async (searchQuery) => {
  if (!isLoggedIn) return { tracks: [], albums: [] };

  try {
    const searchResults = await spotifyApi.search(searchQuery, ['track', 'album'], { limit: 5 });
    console.log('Spotify search results:', searchResults);

    return {
      tracks: searchResults.tracks.items.map(track => ({
        id: track.id,
        name: track.name,
        artists: track.artists.map(artist => ({ name: artist.name })),
        album: {
          id: track.album.id,
          name: track.album.name,
          images: track.album.images
        },
        preview_url: track.preview_url,
        track_number: track.track_number
      })),
      albums: searchResults.albums.items.map(album => ({
        id: album.id,
        name: album.name,
        artists: album.artists.map(artist => ({ name: artist.name })),
        images: album.images,
        total_tracks: album.total_tracks
      }))
    };
  } catch (error) {
    console.error('Error searching Spotify:', error);
    return { tracks: [], albums: [] };
  }
};

export const getSpotifyLoginStatus = () => isLoggedIn;

export const getSpotifyAlbumTracks = async (albumId) => {
  if (!isLoggedIn) return [];

  try {
    const album = await spotifyApi.getAlbum(albumId);
    return album.tracks.items.map(track => ({
      id: track.id,
      name: track.name,
      track_number: track.track_number,
      artists: track.artists.map(artist => ({ name: artist.name }))
    }));
  } catch (error) {
    console.error('Error fetching Spotify album tracks:', error);
    return [];
  }
};

export const getSpotifyAlbumArtistForSong = async (trackId) => {
  if (!isLoggedIn) return { albumId: null, albumName: null, albumArtists: [], trackNumber: null };

  try {
    const track = await spotifyApi.getTrack(trackId);
    return {
      albumId: track.album.id,
      albumName: track.album.name,
      albumArtists: track.album.artists.map(artist => artist.name),
      trackNumber: track.track_number
    };
  } catch (error) {
    console.error('Error fetching Spotify track info:', error);
    return { albumId: null, albumName: null, albumArtists: [], trackNumber: null };
  }
};