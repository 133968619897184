import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { setAuthToken } from '../services/auth';
import API_URL from '../config';

const withRoleCheck = (WrappedComponent, requiredRole) => {
  return function WithRoleCheck(props) {
    const [hasAccess, setHasAccess] = useState(null);

    useEffect(() => {
      const checkAccess = async () => {
        try {
          setAuthToken(localStorage.getItem('token'));
          const response = await axios.get(`${API_URL}check-role/${requiredRole}/`);
          setHasAccess(response.data.hasAccess);
        } catch (error) {
          console.error('Error checking role:', error);
          setHasAccess(false);
        }
      };

      checkAccess();
    }, []);

    if (hasAccess === null) {
      return <div>Loading...</div>;
    }

    if (!hasAccess) {
      return <Navigate to="/unauthorized" replace />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withRoleCheck;