import { useState, useRef, useEffect } from 'react';

function useAudioPlayer(currentService, musicKit) {
    const [playing, setPlaying] = useState(null);
    const audioRef = useRef(new Audio());

    const playPreview = (previewUrl, id) => {
        if (playing === id) {
            if (currentService === 'apple_music' && musicKit) {
                musicKit.pause();
            } else {
                audioRef.current.pause();
            }
            setPlaying(null);
        } else {
            if (currentService === 'apple_music' && musicKit) {
                musicKit.setQueue({ song: id }).then(() => {
                    musicKit.play();
                    setPlaying(id);
                });
            } else {
                audioRef.current.src = previewUrl;
                audioRef.current.play();
                setPlaying(id);
            }
        }
    };

    useEffect(() => {
        return () => {
            if (currentService === 'apple_music' && musicKit) {
                musicKit.pause();
            } else {
                audioRef.current.pause();
            }
        };
    }, [currentService, musicKit]);

    return { playing, playPreview };
}

export default useAudioPlayer;