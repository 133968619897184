import axios from 'axios';
import { setAuthToken } from './auth';
import API_URL from '../config';

export const addToQueue = async (mediaType, mediaId) => {
  setAuthToken(localStorage.getItem('token'));
  const response = await axios.post(`${API_URL}queue/add/`, { media_type: mediaType, media_id: mediaId });
  return response.data;
};

export const moveToCollection = async (queueItemId) => {
  setAuthToken(localStorage.getItem('token'));
  const response = await axios.post(`${API_URL}queue/move-to-collection/${queueItemId}/`);
  return response.data;
};

export const removeFromQueue = async (queueItemId) => {
  setAuthToken(localStorage.getItem('token'));
  const response = await axios.delete(`${API_URL}queue/remove/${queueItemId}/`);
  return response.data;
};