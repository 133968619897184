import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { setAuthToken } from '../services/auth';
import API_URL from '../config';
import { addToQueue, moveToCollection, removeFromQueue } from '../services/mediaService';

function PlaylistPage() {
  const [playlist, setPlaylist] = useState(null);
  const { id } = useParams();
  const [userQueue, setUserQueue] = useState([]);
  const [userCollection, setUserCollection] = useState([]);
  const [inQueue, setInQueue] = useState(false);
  const [inCollection, setInCollection] = useState(false);
  const [trackCollectionStatus, setTrackCollectionStatus] = useState({});
  const [collectionLoading, setCollectionLoading] = useState(false);
  const [queueLoading, setQueueLoading] = useState(false);
  const [trackLoadingStatus, setTrackLoadingStatus] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAuthToken(localStorage.getItem('token'));
        const [playlistResponse, queueResponse, collectionResponse] = await Promise.all([
          axios.get(`${API_URL}playlists/${id}/`),
          axios.get(`${API_URL}user-queue/`),
          axios.get(`${API_URL}user-media/`)
        ]);
        setPlaylist(playlistResponse.data);
        setUserQueue(queueResponse.data);
        setUserCollection(collectionResponse.data);

        // Set track collection status
        const trackStatus = {};
        const loadingStatus = {};
        playlistResponse.data.songs.forEach(song => {
          trackStatus[song.id] = collectionResponse.data.some(item => item.song && item.song.id === song.id);
          loadingStatus[song.id] = false;
        });
        setTrackCollectionStatus(trackStatus);
        setTrackLoadingStatus(loadingStatus);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (playlist && userQueue && userCollection) {
      const playlistInQueue = userQueue.some(item => item && item.media_type === 'playlist' && item.media_id === playlist.id);
      const playlistInCollection = userCollection.some(item => item && item.playlist && item.playlist.id === playlist.id);
      
      setInQueue(playlistInQueue);
      setInCollection(playlistInCollection);
    }
  }, [playlist, userQueue, userCollection]);

  if (!playlist) return <div>Loading...</div>;

  const handleAddToQueue = async () => {
    try {
      setQueueLoading(true);
      await addToQueue('playlist', playlist.id);
      await checkQueueStatus();
    } catch (error) {
      console.error('Error adding to queue:', error);
    } finally {
      setQueueLoading(false);
    }
  };

  const handleRemoveFromQueue = async () => {
    try {
      setQueueLoading(true);
      const queueItem = userQueue.find(item => item.media_type === 'playlist' && item.media_id === playlist.id);
      if (queueItem) {
        await removeFromQueue(queueItem.id);
        await checkQueueStatus();
      }
    } catch (error) {
      console.error('Error removing from queue:', error);
    } finally {
      setQueueLoading(false);
    }
  };

  const checkQueueStatus = async () => {
    try {
      setAuthToken(localStorage.getItem('token'));
      const queueResponse = await axios.get(`${API_URL}user-queue/`);
      const updatedUserQueue = queueResponse.data;
      setUserQueue(updatedUserQueue);
      const playlistInQueue = updatedUserQueue.some(item => item.media_type === 'playlist' && item.media_id === playlist.id);
      setInQueue(playlistInQueue);
    } catch (error) {
      console.error('Error checking queue status:', error);
    }
  };

  const handleAddTrackToCollection = async (trackId) => {
    try {
      setTrackLoadingStatus(prev => ({ ...prev, [trackId]: true }));
      await axios.post(`${API_URL}add-to-collection/`, {
        media_type: 'song',
        media_id: trackId
      });
      setTrackCollectionStatus(prev => ({ ...prev, [trackId]: true }));
      await fetchUserMedia();
    } catch (error) {
      console.error('Error adding track to collection:', error);
    } finally {
      setTrackLoadingStatus(prev => ({ ...prev, [trackId]: false }));
    }
  };

  const handleRemoveTrackFromCollection = async (trackId) => {
    try {
      setTrackLoadingStatus(prev => ({ ...prev, [trackId]: true }));
      await axios.delete(`${API_URL}user-media/song/${trackId}/`);
      setTrackCollectionStatus(prev => ({ ...prev, [trackId]: false }));
      await fetchUserMedia();
    } catch (error) {
      console.error('Error removing track from collection:', error);
    } finally {
      setTrackLoadingStatus(prev => ({ ...prev, [trackId]: false }));
    }
  };

  const handleAddToCollection = async () => {
    try {
      setCollectionLoading(true);
      await axios.post(`${API_URL}add-to-collection/`, {
        media_type: 'playlist',
        media_id: playlist.id
      });
      setInCollection(true);
      setTrackCollectionStatus(prevStatus => {
        const newStatus = { ...prevStatus };
        playlist.songs.forEach(song => {
          newStatus[song.id] = true;
        });
        return newStatus;
      });
      await fetchUserMedia();
    } catch (error) {
      console.error('Error adding to collection:', error);
    } finally {
      setCollectionLoading(false);
    }
  };

  const handleRemoveFromCollection = async () => {
    try {
      setCollectionLoading(true);
      await axios.delete(`${API_URL}user-media/playlist/${playlist.id}/`);
      setInCollection(false);
      setTrackCollectionStatus(prevStatus => {
        const newStatus = { ...prevStatus };
        playlist.songs.forEach(song => {
          newStatus[song.id] = false;
        });
        return newStatus;
      });
      await fetchUserMedia();
    } catch (error) {
      console.error('Error removing from collection:', error);
    } finally {
      setCollectionLoading(false);
    }
  };

  const fetchUserMedia = async () => {
    try {
      setAuthToken(localStorage.getItem('token'));
      const collectionResponse = await axios.get(`${API_URL}user-media/`);
      setUserCollection(collectionResponse.data);
    } catch (error) {
      console.error('Error fetching user media:', error);
    }
  };

  const renderListenLinks = (song) => {
    const links = [];
    if (song.spotify_id) links.push(<a key="spotify" href={`https://open.spotify.com/track/${song.spotify_id}`} target="_blank" rel="noopener noreferrer">Spotify</a>);
    if (song.apple_id) links.push(<a key="apple" href={`https://music.apple.com/us/song/${song.apple_id}`} target="_blank" rel="noopener noreferrer">Apple Music</a>);
    if (song.bandcamp_url) links.push(<a key="bandcamp" href={song.bandcamp_url} target="_blank" rel="noopener noreferrer">Bandcamp</a>);
    if (song.youtube_id) links.push(<a key="youtube" href={`https://www.youtube.com/watch?v=${song.youtube_id}`} target="_blank" rel="noopener noreferrer">YouTube</a>);
    return links.length > 0 ? links.reduce((prev, curr) => [prev, ' | ', curr]) : 'No links available';
  };

  return (
    <div>
      <h1>{playlist.title}</h1>
      <p>Songs: {playlist.songs.length}</p>
      <button 
        onClick={inQueue ? handleRemoveFromQueue : handleAddToQueue} 
        className={inQueue ? "remove-button" : "add-button"}
        disabled={queueLoading}
      >
        {queueLoading ? 'Loading...' : (inQueue ? '- Queue' : '+ Queue')}
      </button>
      <br />
      <button 
        onClick={inCollection ? handleRemoveFromCollection : handleAddToCollection} 
        className={inCollection ? "remove-button" : "add-button"}
        disabled={collectionLoading}
      >
        {collectionLoading ? 'Loading...' : (inCollection ? '- Collection' : '+ Collection')}
      </button>
      <p>
        {playlist.is_external && playlist.src_url && (
        <a href={playlist.src_url} target="_blank" rel="noopener noreferrer">
          Listen
        </a>
      )}
      </p>
      <h2>Tracks:</h2>
      <ol>
        {playlist.songs.map(song => (
          <li key={song.id}>
            <Link to={`/song/${song.id}`}>{song.title}</Link> by {song.primary_artists.map(artist => (
              <Link key={artist.id} to={`/artist/${artist.id}`}>{artist.name}</Link>
            )).reduce((prev, curr) => [prev, ', ', curr])} - {renderListenLinks(song)}
            <button 
              onClick={() => trackCollectionStatus[song.id] 
                ? handleRemoveTrackFromCollection(song.id) 
                : handleAddTrackToCollection(song.id)
              } 
              className={trackCollectionStatus[song.id] ? "remove-button" : "add-button"}
              disabled={trackLoadingStatus[song.id]}
            >
              {trackLoadingStatus[song.id] 
                ? 'Loading...' 
                : (trackCollectionStatus[song.id] ? '- Collection' : '+ Collection')
              }
            </button>
          </li>
        ))}
      </ol>
    </div>
  );
}

export default PlaylistPage;