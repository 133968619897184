// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: 'IBM Plex Mono', monospace;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 10px; /* Add padding to the body */
}

@media (min-width: 600px) {
  body {
    padding: 0; /* Remove padding for larger screens */
  }
}

button {
  margin: 0;
  font-family: 'IBM Plex Mono', monospace;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul, ol {
  list-style-type: none;
  padding-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,uCAAuC;EACvC,eAAe;EACf,mCAAmC;EACnC,kCAAkC;EAClC,eAAe,EAAE,4BAA4B;AAC/C;;AAEA;EACE;IACE,UAAU,EAAE,sCAAsC;EACpD;AACF;;AAEA;EACE,SAAS;EACT,uCAAuC;EACvC,eAAe;EACf,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":["body {\n  margin: 0;\n  font-family: 'IBM Plex Mono', monospace;\n  font-size: 16px;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  padding: 0 10px; /* Add padding to the body */\n}\n\n@media (min-width: 600px) {\n  body {\n    padding: 0; /* Remove padding for larger screens */\n  }\n}\n\nbutton {\n  margin: 0;\n  font-family: 'IBM Plex Mono', monospace;\n  font-size: 12px;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\nul, ol {\n  list-style-type: none;\n  padding-left: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
