import axios from 'axios';
import { getAppleMusicAlbumTracks, getAppleMusicAlbumForSong, getAppleMusicItemArtists, composeAppleMusicArtistNames } from '../services/appleMusicService';
import { getSpotifyAlbumTracks, getSpotifyAlbumArtistForSong } from '../services/spotifyService';
import { addToQueue } from '../services/mediaService';
import API_URL from '../config';

export const handleSelect = async (item, currentService, queueOnly = false) => {
    try {
        const token = localStorage.getItem('token');
        let endpoint = `${API_URL}add-media/`;
        let data = {
            type: item.type,
            service: currentService === 'apple_music' ? 'apple_music' : currentService,
            queue_only: queueOnly
        };

        data[`service_${item.type}_id`] = item.id;

        if (currentService === 'apple_music') {
            const artistsData = await getAppleMusicItemArtists(item.id, item.type);
            const { cleanTitle, allArtists } = composeAppleMusicArtistNames(item.name, artistsData.map(artist => artist.name));
            data.name = cleanTitle;
            data.artists = allArtists;
        } else {
            data.name = item.name;
            data.artists = item.artists.map(artist => artist.name);
        }

        if (item.type === 'album') {
            const tracks = await (currentService === 'spotify' 
                ? getSpotifyAlbumTracks(item.id) 
                : getAppleMusicAlbumTracks(item.id));
            console.log('Processed tracks:', tracks);
            data.tracks = await Promise.all(tracks.map(async track => {
                if (currentService === 'apple_music') {
                    const trackArtistsData = await getAppleMusicItemArtists(track.id, 'song');
                    const { cleanTitle, allArtists } = composeAppleMusicArtistNames(track.name, trackArtistsData.map(artist => artist.name));
                    return {
                        ...track,
                        name: cleanTitle,
                        artists: allArtists
                    };
                } else {
                    return {
                        ...track,
                        artists: track.artists.map(artist => artist.name)
                    };
                }
            }));
            
            // Fetch and add album artists
            let albumInfo;
            if (currentService === 'spotify') {
                albumInfo = await getSpotifyAlbumArtistForSong(tracks[0].id);
                data.album_artists = albumInfo.albumArtists; // Assuming it now returns an array of artists
            } else if (currentService === 'apple_music') {
                console.log('ITEM ID', item.id);
                const separatedArtists = await getAppleMusicItemArtists(item.id, 'album');
                data.album_artists = separatedArtists.map(artist => artist.name);
            }

        } else if (item.type === 'song') {
            let albumInfo;
            if (currentService === 'spotify') {
                albumInfo = await getSpotifyAlbumArtistForSong(item.id);
                data.album_artists = albumInfo.albumArtists; // Assuming it now returns an array of artists
            } else if (currentService === 'apple_music') {
                albumInfo = await getAppleMusicAlbumForSong(item.id);
                const albumArtists = await getAppleMusicItemArtists(albumInfo.albumId, 'album');
                data.album_artists = albumArtists.map(artist => artist.name);
            }

            console.log('Album Info:', albumInfo);

            data.service_album_id = albumInfo.albumId;
            data.track_number = albumInfo.trackNumber;
            data.album_name = albumInfo.albumName;
            data.duration = item.duration_ms; // or the appropriate duration field
        }

        console.log('Final data object before sending:', data);

        const response = await axios.post(endpoint, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        console.log('Added successfully:', response.data);

        if (queueOnly) {
            // Add to queue instead of collection
            await addToQueue(item.type, response.data.id);
            console.log('Added to queue successfully');
            return response; // Add this line to return the response object
        } else {
            console.log('Added to collection successfully');
        }
    } catch (error) {
        console.error('Error adding media:', error);
    }
};

export const handleAddToQueue = async (item, currentService) => {
  try {
    const response = await handleSelect(item, currentService, true);
    if (response && (response.status === 200 || response.status === 201)) {
      console.log('Queue response:', response.data);
      return response.data;  // Return the data, not the entire response object
    } else {
      throw new Error('Unexpected response status');
    }
  } catch (error) {
    console.error('Error adding to queue:', error);
    throw error;
  }
};

export const handleInfo = async (item, currentService) => {
    if (item.type === 'song') {
        console.log('Song Info:', item);
        if (currentService === 'apple_music') {
            try {
                const albumInfo = await getAppleMusicAlbumForSong(item.id);
                const separatedArtists = await getAppleMusicItemArtists(item.id);
                if (albumInfo.error) {
                    console.log('Album Info Error:', albumInfo.error);
                } else {
                    console.log('Apple Music Album Info:', albumInfo);
                    console.log('Separated artists:', separatedArtists);
                }
            } catch (error) {
                console.error('Error fetching Apple Music album info:', error);
            }
        } else if (currentService === 'spotify') {
            try {
                const albumInfo = await getSpotifyAlbumArtistForSong(item.id);
                console.log('Spotify Album Info:', albumInfo);
            } catch (error) {
                console.error('Error fetching Spotify album info:', error);
            }
        }
    } else if (item.type === 'album') {
        if (currentService === 'spotify') {
            const tracks = await getSpotifyAlbumTracks(item.id);
            console.log('Album Info:', item);
            console.log('Album Tracks:', tracks);
        } else if (currentService === 'apple_music') {
            const tracks = await getAppleMusicAlbumTracks(item.id);
            const separatedArtists = await getAppleMusicItemArtists(item.id, 'album');
            console.log('Album Info:', item);
            console.log('Album Tracks:', tracks);
            console.log('Separated artists:', separatedArtists);
        }
    }
};