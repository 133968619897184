import React, { useState, useEffect } from 'react';
import { handleSelect, handleInfo, handleAddToQueue } from '../utils/mediaHandlers';
import axios from 'axios';
import { setAuthToken } from '../services/auth';
import API_URL from '../config';

function SearchResults({ results, currentService, playing, playPreview }) {
    const [userCollection, setUserCollection] = useState([]);
    const [userQueue, setUserQueue] = useState([]);
    const [loadingItems, setLoadingItems] = useState({});

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setAuthToken(localStorage.getItem('token'));
                const [collectionResponse, queueResponse] = await Promise.all([
                    axios.get(`${API_URL}user-media/`),
                    axios.get(`${API_URL}user-queue/`)
                ]);
                setUserCollection(collectionResponse.data);
                setUserQueue(queueResponse.data);
                console.log('Fetched user collection:', collectionResponse.data);
                console.log('Fetched user queue:', queueResponse.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []);

    const isInCollection = (item) => {
        const inCollection = userCollection.some(media => {
            if (item.type === 'song' && media.song) {
                return media.song.spotify_id === item.id || media.song.apple_id === item.id;
            } else if (item.type === 'album' && media.album) {
                return media.album.spotify_id === item.id || media.album.apple_id === item.id;
            } else if (item.type === 'playlist' && media.playlist) {
                return media.playlist.lib_id === item.id;
            }
            return false;
        });
        return inCollection;
    };

    const isInQueue = (item) => {
        const inQueue = userQueue.some(queueItem => {
            if (item.type === queueItem.media_type) {
                if (item.type === 'song' || item.type === 'album') {
                    return queueItem.spotify_id === item.id || queueItem.apple_id === item.id;
                }
                return queueItem.media_id === item.id;
            }
            return false;
        });
        return inQueue;
    };

    const handleCollectionClick = async (item) => {
        console.log(`Handling collection click for item:`, item);
        setLoadingItems(prev => ({ ...prev, [item.id]: true }));
        try {
            if (isInCollection(item)) {
                const collectionItem = userCollection.find(media => {
                    if (item.type === 'song' && media.song) {
                        return media.song.spotify_id === item.id || media.song.apple_id === item.id;
                    } else if (item.type === 'album' && media.album) {
                        return media.album.spotify_id === item.id || media.album.apple_id === item.id;
                    } else if (item.type === 'playlist' && media.playlist) {
                        return media.playlist.lib_id === item.id;
                    }
                    return false;
                });
                console.log('Collection item:', collectionItem);
                if (collectionItem) {
                    console.log(`Removing item ${item.name} (ID: ${item.id}, Type: ${item.type}) from collection`);
                    const itemId = collectionItem[item.type].id;
                    await axios.delete(`${API_URL}user-media/${item.type}/${itemId}/`);
                } else {
                    console.log('Collection item not found');
                }
            } else {
                console.log(`Adding item ${item.name} (ID: ${item.id}, Type: ${item.type}) to collection`);
                await handleSelect(item, currentService);
            }
            // Refresh user collection data
            const collectionResponse = await axios.get(`${API_URL}user-media/`);
            setUserCollection(collectionResponse.data);
            console.log('Updated user collection:', collectionResponse.data);
        } catch (error) {
            console.error('Error handling collection click:', error.response || error);
        } finally {
            setLoadingItems(prev => ({ ...prev, [item.id]: false }));
        }
    };

    const handleQueueClick = async (item) => {
        console.log(`Handling queue click for item ${item.name} (ID: ${item.id})`);
        setLoadingItems(prev => ({ ...prev, [item.id]: true }));
        try {
            if (isInQueue(item)) {
                const queueItem = userQueue.find(queueItem => 
                    queueItem.media_type === item.type && 
                    (queueItem.spotify_id === item.id || queueItem.apple_id === item.id || queueItem.media_id === item.id)
                );
                if (queueItem) {
                    console.log(`Removing item ${item.name} (ID: ${item.id}) from queue`);
                    await axios.delete(`${API_URL}queue/remove/${queueItem.id}/`);
                } else {
                    console.log('Queue item not found');
                }
            } else {
                console.log(`Adding item ${item.name} (ID: ${item.id}) to queue`);
                const queueData = await handleAddToQueue(item, currentService);
                console.log('Added to queue:', queueData);
            }
            // Refresh user queue data
            const queueResponse = await axios.get(`${API_URL}user-queue/`);
            setUserQueue(queueResponse.data);
            console.log('Updated user queue:', queueResponse.data);
        } catch (error) {
            console.error('Error handling queue click:', error);
        } finally {
            setLoadingItems(prev => ({ ...prev, [item.id]: false }));
        }
    };

    return (
        <>
            {results.tracks && (
                <div>
                    <h3>Tracks</h3>
                    <ul>
                        {results.tracks.map(item => (
                            <li key={item.id}>
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    {item.album.images.length > 0 && (
                                        <img 
                                            src={item.album.images[item.album.images.length - 1].url} 
                                            alt={`${item.album.name} cover`}
                                            style={{width: '40px', height: '40px', marginRight: '10px'}}
                                        />
                                    )}
                                    {item.preview_url && (
                                        <button 
                                            onClick={() => playPreview(item.preview_url, item.id)}
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                background: 'rgba(0,0,0,0.5)',
                                                color: 'white',
                                                border: 'none',
                                                borderRadius: '50%',
                                                width: '20px',
                                                height: '20px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {playing === item.id ? '⏸' : '▶'}
                                        </button>
                                    )}
                                </div>
                                <span>
                                    {item.name} by {item.artists.map(artist => artist.name).join(', ')}
                                </span>
                                <button 
                                  onClick={() => handleCollectionClick({...item, type: 'song'})} 
                                  className={isInCollection({...item, type: 'song'}) ? "remove-button" : "add-button"}
                                  disabled={loadingItems[item.id]}
                                >
                                  {loadingItems[item.id] ? 'Loading...' : (isInCollection({...item, type: 'song'}) ? '- Collection' : '+ Collection')}
                                </button>
                                <button 
                                  onClick={() => handleQueueClick({...item, type: 'song'})} 
                                  className={isInQueue({...item, type: 'song'}) ? "remove-button" : "add-button"}
                                  disabled={loadingItems[item.id]}
                                >
                                  {loadingItems[item.id] ? 'Loading...' : (isInQueue({...item, type: 'song'}) ? '- Queue' : '+ Queue')}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {results.albums && (
                <div>
                    <h3>Albums</h3>
                    <ul>
                        {results.albums.map(item => (
                            <li key={item.id}>
                                {item.images.length > 0 && (
                                    <img 
                                        src={item.images[item.images.length - 1].url} 
                                        alt={`${item.name} cover`}
                                        style={{width: '40px', height: '40px', marginRight: '10px'}}
                                    />
                                )}
                                <span>
                                    {item.name} by {item.artists.map(artist => artist.name).join(', ')} (from {currentService})
                                </span>
                                <button 
                                  onClick={() => handleCollectionClick({...item, type: 'album'})} 
                                  className={isInCollection({...item, type: 'album'}) ? "remove-button" : "add-button"}
                                  disabled={loadingItems[item.id]}
                                >
                                  {loadingItems[item.id] ? 'Loading...' : (isInCollection({...item, type: 'album'}) ? '- Collection' : '+ Collection')}
                                </button>
                                <button 
                                  onClick={() => handleQueueClick({...item, type: 'album'})} 
                                  className={isInQueue({...item, type: 'album'}) ? "remove-button" : "add-button"}
                                  disabled={loadingItems[item.id]}
                                >
                                  {loadingItems[item.id] ? 'Loading...' : (isInQueue({...item, type: 'album'}) ? '- Queue' : '+ Queue')}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {results.playlists && (
                <div>
                    <h3>Playlists</h3>
                    <ul>
                        {results.playlists.map(item => (
                            <li key={item.id} onClick={() => handleCollectionClick({...item, type: 'playlist'})}>
                                {item.images.length > 0 && (
                                    <img 
                                        src={item.images[item.images.length - 1].url} 
                                        alt={`${item.name} cover`}
                                        style={{width: '40px', height: '40px', marginRight: '10px'}}
                                    />
                                )}
                                {item.name} by {item.owner.display_name} (from {currentService})
                                <button 
                                  onClick={() => handleQueueClick({...item, type: 'playlist'})} 
                                  className={isInQueue({...item, type: 'playlist'}) ? "remove-button" : "add-button"}
                                  disabled={loadingItems[item.id]}
                                >
                                  {loadingItems[item.id] ? 'Loading...' : (isInQueue({...item, type: 'playlist'}) ? '- Queue' : '+ Queue')}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
}

export default SearchResults;