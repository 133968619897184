import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import CollageForm from './CollageForm';
import API_URL from '../config';

function CollagePage() {
  const [collage, setCollage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [items, setItems] = useState([]);
  const { id } = useParams();
  const [userCollection, setUserCollection] = useState([]);
  const [itemCollectionStatus, setItemCollectionStatus] = useState({});
  const [itemLoadingStatus, setItemLoadingStatus] = useState({});

  useEffect(() => {
    fetchCollage();
  }, [id]);

  const fetchCollage = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: { 'Authorization': `Bearer ${token}` }
      };
      const [collageResponse, collectionResponse] = await Promise.all([
        axios.get(`${API_URL}collages/${id}/`, config),
        axios.get(`${API_URL}user-media/`, config)
      ]);
      setCollage(collageResponse.data);
      setItems(collageResponse.data.items);
      setUserCollection(collectionResponse.data);

      // Set item collection status
      const collectionStatus = {};
      const loadingStatus = {};
      collageResponse.data.items.forEach(item => {
        collectionStatus[item.id] = collectionResponse.data.some(
          media => media[item.type] && media[item.type].id === item.id
        );
        loadingStatus[item.id] = false;
      });
      setItemCollectionStatus(collectionStatus);
      setItemLoadingStatus(loadingStatus);
    } catch (error) {
      console.error('Error fetching collage:', error);
      if (error.response && error.response.status === 401) {
        alert('You are not authorized to view this collage. Please log in.');
      }
    }
  };

  const handleRemoveFromCollage = async (type, itemId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}collages/${id}/remove/`, 
        { media_type: type, media_id: itemId },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      fetchCollage();
    } catch (error) {
      console.error('Error removing from collage:', error);
      if (error.response && error.response.status === 401) {
        alert('You are not authorized to remove this item. Please log in again.');
      }
    }
  };

  const handleEditSubmit = async (updatedCollage) => {
    try {
      const token = localStorage.getItem('token');
      await axios.patch(`${API_URL}collages/${id}/update/`, 
        { 
          ...updatedCollage, 
          items: items.map((item, index) => ({ 
            id: item.id, 
            type: item.type, 
            order: index 
          })) 
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setIsEditing(false);
      fetchCollage();
    } catch (error) {
      console.error('Error updating collage:', error);
      alert('Error updating collage. Please try again.');
    }
  };

  const moveItem = (index, direction) => {
    const newItems = [...items];
    const item = newItems[index];
    newItems.splice(index, 1);
    newItems.splice(index + direction, 0, item);
    setItems(newItems);
  };

  const handleAddToCollection = async (item) => {
    try {
      setItemLoadingStatus(prev => ({ ...prev, [item.id]: true }));
      await axios.post(`${API_URL}add-to-collection/`, {
        media_type: item.type,
        media_id: item.id
      });
      setItemCollectionStatus(prev => ({ ...prev, [item.id]: true }));
      await fetchCollage();
    } catch (error) {
      console.error('Error adding to collection:', error);
    } finally {
      setItemLoadingStatus(prev => ({ ...prev, [item.id]: false }));
    }
  };

  const handleRemoveFromCollection = async (item) => {
    try {
      setItemLoadingStatus(prev => ({ ...prev, [item.id]: true }));
      await axios.delete(`${API_URL}user-media/${item.type}/${item.id}/`);
      setItemCollectionStatus(prev => ({ ...prev, [item.id]: false }));
      await fetchCollage();
    } catch (error) {
      console.error('Error removing from collection:', error);
    } finally {
      setItemLoadingStatus(prev => ({ ...prev, [item.id]: false }));
    }
  };

  if (!collage) return <div>Loading...</div>;

  const getItemLink = (item) => {
    switch (item.type) {
      case 'song':
        return `/song/${item.id}`;
      case 'album':
        return `/album/${item.id}`;
      case 'playlist':
        return `/playlist/${item.id}`;
      case 'mix':
        return `/mix/${item.id}`;
      case 'collage':
        return `/collage/${item.id}`;
      default:
        return '#';
    }
  };

  const renderItemDetails = (item, index) => {
    return (
      <div className="left-justify">
        <Link to={getItemLink(item)}>{item.title}</Link>
        {item.primary_artists && (
          <span> by {item.primary_artists.map(artist => (
            <Link key={artist.id} to={`/artist/${artist.id}`}>{artist.name}</Link>
          )).reduce((prev, curr) => [prev, ', ', curr])}</span>
        )}
        <span> ({item.type})</span>
        {isEditing ? (
          <div>
            <button onClick={() => moveItem(index, -1)} disabled={index === 0}>↑</button>
            <button onClick={() => moveItem(index, 1)} disabled={index === items.length - 1}>↓</button>
          </div>
        ) : (
          <div>
            <button 
              onClick={() => itemCollectionStatus[item.id] 
                ? handleRemoveFromCollection(item) 
                : handleAddToCollection(item)
              } 
              className={itemCollectionStatus[item.id] ? "remove-button" : "add-button"}
              disabled={itemLoadingStatus[item.id]}
            >
              {itemLoadingStatus[item.id] 
                ? 'Loading...' 
                : (itemCollectionStatus[item.id] ? '- Collection' : '+ Collection')
              }
            </button>
            <button 
              onClick={() => handleRemoveFromCollage(item.type, item.id)} 
              className="remove-button"
            >
              X
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="library-container">
      {isEditing ? (
        <CollageForm collage={collage} onSubmit={handleEditSubmit} />
      ) : (
        <>
          <h1>{collage.title}</h1>
          <p>{collage.description}</p>
          <button onClick={() => setIsEditing(true)}>Edit Collage</button>
        </>
      )}

      <div className="centered-list">
        <ul className="left-justify">
          {items.map((item, index) => (
            <li key={`${item.type}-${item.id}`}>
              {renderItemDetails(item, index)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default CollagePage;