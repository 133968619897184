import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useNavigate } from 'react-router-dom';
import './App.css';
import Library from './components/Library';
import Login from './components/Login';
import UniversalAdd from './components/UniversalAdd';
import { isAuthenticated, logout, setupTokenRefresh } from './services/auth';
import SongPage from './components/SongPage';
import AlbumPage from './components/AlbumPage';
import PlaylistPage from './components/PlaylistPage';
import MixPage from './components/MixPage';
import ArtistPage from './components/ArtistPage';
import DatabaseEditor from './components/DatabaseEditor';
import Unauthorized from './components/Unauthorized';
import CollageForm from './components/CollageForm';
import CollagePage from './components/CollagePage';
import Friends from './components/Friends';
import Recs from './components/Recs';
import SignUp from './components/SignUp';

function NavigationBar({ auth, handleLogout }) {
  const navigate = useNavigate();

  return (
    <nav>
      <button onClick={() => navigate(-1)}>back</button>
      <ul>
        {auth ? (
          <>
            <li><Link to="/library">library</Link></li>
            <li><Link to="/add">add</Link></li>
            <li><Link to="/friends">friends</Link></li>
            <li><Link to="/recs">recs</Link></li>
            <li><button onClick={handleLogout}>logout</button></li>
          </>
        ) : (
          <li><Link to="/login">login</Link></li>
        )}
      </ul>
    </nav>
  );
}

function App() {
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    setAuth(isAuthenticated());
  }, []);

  const handleLogout = () => {
    logout();
    setAuth(false);
  };

  useEffect(() => {
    if (auth) {
      setupTokenRefresh();
    }
  }, [auth]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js-cdn.music.apple.com/musickit/v1/musickit.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <NavigationBar auth={auth} handleLogout={handleLogout} />
        <Routes>
          <Route path="/login" element={!auth ? <Login setAuth={setAuth} /> : <Navigate to="/library" />} />
          <Route path="/Library" element={auth ? <Library /> : <Navigate to="/login" />} />
          <Route path="/add" element={<UniversalAdd />} />
          <Route path="/" element={<h1>Context</h1>} />
          <Route path="/song/:id" element={<SongPage />} />
          <Route path="/album/:id" element={<AlbumPage />} />
          <Route path="/playlist/:id" element={<PlaylistPage />} />
          <Route path="/mix/:id" element={<MixPage />} />
          <Route path="/artist/:id" element={<ArtistPage />} />
          <Route path="/database-editor" element={<DatabaseEditor />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/collage/new" element={<CollageForm onSubmit={(data) => console.log('Collage created:', data)} />} />
          <Route path="/collage/:id" element={<CollagePage />} />
          <Route path="/friends" element={auth ? <Friends /> : <Navigate to="/login" />} />
          <Route path="/recs" element={auth ? <Recs /> : <Navigate to="/login" />} />
          <Route path="/signup" element={!auth ? <SignUp /> : <Navigate to="/library" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;