import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { setAuthToken } from '../services/auth';
import API_URL from '../config';
import { addToQueue, moveToCollection, removeFromQueue } from '../services/mediaService';

function AlbumPage() {
  const [album, setAlbum] = useState(null);
  const { id } = useParams();
  const [userQueue, setUserQueue] = useState([]);
  const [userCollection, setUserCollection] = useState([]);
  const [inQueue, setInQueue] = useState(false);
  const [inCollection, setInCollection] = useState(false);
  const [trackCollectionStatus, setTrackCollectionStatus] = useState({});
  const [collectionLoading, setCollectionLoading] = useState(false);
  const [queueLoading, setQueueLoading] = useState(false);
  const [trackLoadingStatus, setTrackLoadingStatus] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAuthToken(localStorage.getItem('token'));
        const [albumResponse, queueResponse, collectionResponse] = await Promise.all([
          axios.get(`${API_URL}albums/${id}/`),
          axios.get(`${API_URL}user-queue/`),
          axios.get(`${API_URL}user-media/`)
        ]);
        setAlbum(albumResponse.data);
        setUserQueue(queueResponse.data);
        setUserCollection(collectionResponse.data);
        console.log('Initial userQueue:', queueResponse.data);

        // Set track collection status
        const trackStatus = {};
        const loadingStatus = {};
        albumResponse.data.tracks.forEach(track => {
          trackStatus[track.id] = collectionResponse.data.some(item => item.song && item.song.id === track.id);
          loadingStatus[track.id] = false;
        });
        setTrackCollectionStatus(trackStatus);
        setTrackLoadingStatus(loadingStatus);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (album && userQueue && userCollection) {
      const albumInQueue = userQueue.some(item => item && item.media_type === 'album' && item.media_id === album.id);
      const albumInCollection = userCollection.some(item => item && item.album && item.album.id === album.id);
      
      setInQueue(albumInQueue);
      setInCollection(albumInCollection);
      console.log('Album in Queue:', albumInQueue);
      console.log('Album in Collection:', albumInCollection);
    }
  }, [album, userQueue, userCollection]);

  if (!album) return <div>Loading...</div>;

  const handleAddToQueue = async () => {
    try {
      setQueueLoading(true);
      await addToQueue('album', album.id);
      await checkQueueStatus();
    } catch (error) {
      console.error('Error adding to queue:', error);
    } finally {
      setQueueLoading(false);
    }
  };

  const handleRemoveFromQueue = async () => {
    console.log('Attempting to remove from queue');
    try {
      setQueueLoading(true);
      const queueItem = userQueue.find(item => item.media_type === 'album' && item.media_id === album.id);
      console.log('Queue item found:', queueItem);
      if (queueItem) {
        await removeFromQueue(queueItem.id);
        await checkQueueStatus();
      } else {
        console.log('Queue item not found');
      }
    } catch (error) {
      console.error('Error removing from queue:', error);
    } finally {
      setQueueLoading(false);
    }
  };

  const checkQueueStatus = async () => {
    try {
      setAuthToken(localStorage.getItem('token'));
      const queueResponse = await axios.get(`${API_URL}user-queue/`);
      const updatedUserQueue = queueResponse.data;
      setUserQueue(updatedUserQueue);
      const albumInQueue = updatedUserQueue.some(item => item.media_type === 'album' && item.media_id === album.id);
      setInQueue(albumInQueue);
      console.log('Updated queue status:', albumInQueue);
    } catch (error) {
      console.error('Error checking queue status:', error);
    }
  };

  const handleAddTrackToCollection = async (trackId) => {
    try {
      setTrackLoadingStatus(prev => ({ ...prev, [trackId]: true }));
      await axios.post(`${API_URL}add-to-collection/`, {
        media_type: 'song',
        media_id: trackId
      });
      setTrackCollectionStatus(prev => ({ ...prev, [trackId]: true }));
      await fetchUserMedia();
    } catch (error) {
      console.error('Error adding track to collection:', error);
    } finally {
      setTrackLoadingStatus(prev => ({ ...prev, [trackId]: false }));
    }
  };

  const handleRemoveTrackFromCollection = async (trackId) => {
    try {
      setTrackLoadingStatus(prev => ({ ...prev, [trackId]: true }));
      await axios.delete(`${API_URL}user-media/song/${trackId}/`);
      setTrackCollectionStatus(prev => ({ ...prev, [trackId]: false }));
      await fetchUserMedia();
    } catch (error) {
      console.error('Error removing track from collection:', error);
    } finally {
      setTrackLoadingStatus(prev => ({ ...prev, [trackId]: false }));
    }
  };

  const handleAddToCollection = async () => {
    try {
      setCollectionLoading(true);
      await axios.post(`${API_URL}add-to-collection/`, {
        media_type: 'album',
        media_id: album.id
      });
      setInCollection(true);
      setTrackCollectionStatus(prevStatus => {
        const newStatus = { ...prevStatus };
        album.tracks.forEach(track => {
          newStatus[track.id] = true;
        });
        return newStatus;
      });
      await fetchUserMedia();
    } catch (error) {
      console.error('Error adding to collection:', error);
    } finally {
      setCollectionLoading(false);
    }
  };

  const handleRemoveFromCollection = async () => {
    try {
      setCollectionLoading(true);
      await axios.delete(`${API_URL}user-media/album/${album.id}/`);
      setInCollection(false);
      setTrackCollectionStatus(prevStatus => {
        const newStatus = { ...prevStatus };
        album.tracks.forEach(track => {
          newStatus[track.id] = false;
        });
        return newStatus;
      });
      await fetchUserMedia();
    } catch (error) {
      console.error('Error removing from collection:', error);
    } finally {
      setCollectionLoading(false);
    }
  };

  const fetchUserMedia = async () => {
    try {
      setAuthToken(localStorage.getItem('token'));
      const collectionResponse = await axios.get(`${API_URL}user-media/`);
      setUserCollection(collectionResponse.data);
    } catch (error) {
      console.error('Error fetching user media:', error);
    }
  };

  const renderListenLinks = (track) => {
    const links = [];
    if (track.spotify_id) links.push(<a key="spotify" href={`https://open.spotify.com/track/${track.spotify_id}`} target="_blank" rel="noopener noreferrer">Spotify</a>);
    if (track.apple_id) links.push(<a key="apple" href={`https://music.apple.com/us/song/${track.apple_id}`} target="_blank" rel="noopener noreferrer">Apple Music</a>);
    if (track.bandcamp_url) links.push(<a key="bandcamp" href={track.bandcamp_url} target="_blank" rel="noopener noreferrer">Bandcamp</a>);
    if (track.youtube_id) links.push(<a key="youtube" href={`https://www.youtube.com/watch?v=${track.youtube_id}`} target="_blank" rel="noopener noreferrer">YouTube</a>);
    return links.length > 0 ? links.reduce((prev, curr) => [prev, ' | ', curr]) : 'No links available';
  };

  return (
    <div>
      <h1>{album.title}</h1>
      <p>Artists: {album.primary_artists.map(artist => (
        <Link key={artist.id} to={`/artist/${artist.id}`}>{artist.name}</Link>
      )).reduce((prev, curr) => [prev, ', ', curr])}</p>
      <p>Label: {album.label}</p>
      <button 
        onClick={inQueue ? handleRemoveFromQueue : handleAddToQueue} 
        className={inQueue ? "remove-button" : "add-button"}
        disabled={queueLoading}
      >
        {queueLoading ? 'Loading...' : (inQueue ? '- Queue' : '+ Queue')}
      </button>
      <br />
      <button 
        onClick={inCollection ? handleRemoveFromCollection : handleAddToCollection} 
        className={inCollection ? "remove-button" : "add-button"}
        disabled={collectionLoading}
      >
        {collectionLoading ? 'Loading...' : (inCollection ? '- Collection' : '+ Collection')}
      </button>
      <p>
        Listen on: {' '}
        {album.spotify_id && <a href={`https://open.spotify.com/album/${album.spotify_id}`} target="_blank" rel="noopener noreferrer">Spotify</a>}
        {album.spotify_id && album.apple_id && ' | '}
        {album.apple_id && <a href={`https://music.apple.com/us/album/${album.apple_id}`} target="_blank" rel="noopener noreferrer">Apple Music</a>}
        {(album.spotify_id || album.apple_id) && album.bandcamp_url && ' | '}
        {album.bandcamp_url && <a href={album.bandcamp_url} target="_blank" rel="noopener noreferrer">Bandcamp</a>}
        {(album.spotify_id || album.apple_id || album.bandcamp_url) && album.youtube_id && ' | '}
        {album.youtube_id && <a href={`https://www.youtube.com/playlist?list=${album.youtube_id}`} target="_blank" rel="noopener noreferrer">YouTube</a>}
      </p>
      <h2>Tracks:</h2>
      <ol>
        {album.tracks.map(track => (
          <li key={track.id}>
            <Link to={`/song/${track.id}`}>{track.title}</Link> - {renderListenLinks(track)}
            <button 
              onClick={() => trackCollectionStatus[track.id] 
                ? handleRemoveTrackFromCollection(track.id) 
                : handleAddTrackToCollection(track.id)
              } 
              className={trackCollectionStatus[track.id] ? "remove-button" : "add-button"}
              disabled={trackLoadingStatus[track.id]}
            >
              {trackLoadingStatus[track.id] 
                ? 'Loading...' 
                : (trackCollectionStatus[track.id] ? '- Collection' : '+ Collection')
              }
            </button>
          </li>
        ))}
      </ol>
    </div>
  );
}

export default AlbumPage;